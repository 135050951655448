import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';

import images from '../../constants/images';
import React, { useState } from 'react';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return(
  <nav className="app__navbar">
    <div className="app__navbar-logo">
      <h1 className="headtext__cormorant" style={{color:'#6495ED'}}> Lamour</h1>
      <img src={images.gericht} alt="app logo" style={{display: 'none'}}/>

    </div>
    <ul className="app__navbar-links">

      <li className="p__opensans"><a href="#home">Home</a></li>
      <li className="p__opensans"><a href="#about">About</a></li>
      <li className="p__opensans"><a href="#menu">Menu</a></li>
      <li className="p__opensans"><a href="#awards">Awards</a></li>
      <li className="p__opensans"><a href="#contact">Contact</a></li>
    </ul>
    <div className="app__navbar-login">
      <a href="/" className='p__opensans'>Log In / Register </a>
      <div />
      <a href="/" className="p__opensans" > Book Table</a>

    </div>
    <div className="app__navbar-smallscreen">
      <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
      {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
          <ul className="app__navbar-smallscreen_links">
            <li className="p__opensans"><a href="#home">Home</a></li>
            <li className="p__opensans"><a href="#about">About</a></li>
            <li className="p__opensans"><a href="#menu">Menu</a></li>
            <li className="p__opensans"><a href="#awards">Awards</a></li>
            <li className="p__opensans"><a href="#contact">Contact</a></li>
          </ul>
        </div>
)}
    </div>
  </nav>
  )
};

export default Navbar;
