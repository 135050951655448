import React from 'react';
import { FiFacebook, FiTwitter, FiInstgram, FiInstagram } from 'react-icons/fi'
import  { images } from '../../constants';
import { FooterOverlay, Newsletter } from '../../components';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding">
    <FooterOverlay />
    <Newsletter />
    <div className="app__footer-links">
      <div className="app__footer-links_contacts">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">New York, New York 11001</p>
        <p className="p__opensans">1-347-984-9674 </p>
        <p className="p__opensans">1-347-433-7082</p>
      </div>
      <div className="app__footer-links_logo">
      <h1 className="headtext__cormorant" style={{color:'#6495ED'}}> Lamour</h1>
        <p className="p__opensans">"The best way to find yourself
         is lose yourself in the service of others"</p>
         <img src={images.spoon} className="spoon__img" alt="spoon_img" />
         <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
         </div>
      </div>
      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working hours</h1>
        <p className="p__opensans">Monday - Friday </p>
        <p className="p__opensans">8:00 a.m - 10:00 p.m. </p>
        <p className="p__opensans">Saturday - Sunday </p>
        <p className="p__opensans">7:00 a.m - 11:00 p.m. </p>

      </div>
    </div>
      <div className="footer__copyright">
        <p className="p__opensans">
          2023 Lamour CopyRights. All Rights Protected.
        </p>
      </div>
  </div>
);

export default Footer;
