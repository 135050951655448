import React, { useState } from 'react';
import { meal } from '../../constants';
import { BsFillPlayFill, BsPauseFill} from 'react-icons/bs';
import './Intro.css';

const Intro = () => {
  const [ playVideo, setPlayVideo ] = useState(false);
  const vidRef = React.useRef();
  const handlevideo = () =>{
    setPlayVideo((preplayvideo) => !preplayvideo)
    if(playVideo){
      vidRef.current.pause();
    }else{
      vidRef.current.play()
    }

  }
  return (
  <div className="app__video">
    <video
    ref={vidRef}
    src={meal} 
    type="video/mp4" 
    Loop 
    controls={false} 
    muted
    />
    <div className="app__video-overlay flex__center">
      <div className="app__video-overlay_circle flex__center"
      onClick={handlevideo}>
        {playVideo 
        ? <BsFillPlayFill color="#fff" fontSize={30} />
        : <BsFillPlayFill color="#fff" fontSize={30} />}
           

      </div>
    </div>

  </div>
);
  }
export default Intro;
