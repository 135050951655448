import React from 'react';
import { BsArrowLeftShort, BsArrowRightShort, BsInstagram } from 'react-icons/bs';
import { SubHeading, MenuItem } from '../../components';
import { images, data } from '../../constants';
import './Gallery.css';

const galleryImages = [images.gallery01, images.gallery02, images.gallery03, images.gallery04,]
const Gallery = () => {
  const scrollRef = React.useRef(null);
  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollleft -= 300;
    } else
      current.scrollleft += 300;

  };

//   return (
//     <div className="app__gallery flex__center">
//       <div className="app__gallery-content">
//         <SubHeading title="Instagram" />
//         <div className="text__container">
//         <h1 className="headtext__cormorant">Photo Gallery</h1>
//         <p className="p__copensans" style={{ color: '#AAA', marginTop: '2rem' }}>
//           Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Volutpat Mattis
//           Ipsum Turpis Elit Elit Scelerisque Egestas Mu.
//         </p>
//         <button type='button' className="custom__button">View More</button>
//       </div>
// </div>
//       <div className="app__gallery-images">
//         <div className="app__gallery-images_container" ref={scrollRef}>






//           {galleryImages.map((image, index) => (
//             <div
//             className="app__gallery-images_card flex__center"
//             key={`gallery_image-${index + 1} `
//               }>
//               <img src={image} alt="gallery" />
//               <BsInstagram className="gallery__image-icon" />
//             </div>
//           ))}






//           <div className="app__gallery-images_arrow">
//             <BsArrowLeftShort className="app__gallery-images_arrow-icon" onClick={() => scroll('left')} />
//             <BsArrowRightShort className="app__gallery-images_arrow-icon" onClick={() => scroll('right')} />


//           </div>


//         </div></div>
//     </div>
//   );
}
export default Gallery;
